import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import { ItemsList } from "./utils"
import Label from "./label"

const HeroSolutions = ({ solutions }) => (
  <StyledSolutions>
    {solutions.map((solution, index) => (
      <Solution solution={solution} key={solution.id} index={index} />
    ))}
  </StyledSolutions>
)

const Solution = ({ solution, index }) => (
  <StyledSolution image={solution.heroImage ? solution.heroImage.url : null}>
    <StyledLink to={solution.slug} color={solution.color.hex}>
      <Content>
        <div>
          <CustomLabel>Soluzione 0{index + 1}</CustomLabel>
          <h4>{solution.title}</h4>
          <Subtitle>{solution.subtitle}</Subtitle>
        </div>
        <Number>0{index + 1}</Number>
      </Content>
    </StyledLink>
  </StyledSolution>
)

const StyledSolutions = styled(ItemsList)`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 769px) {
    flex-direction: row;
    height: 400px;
    min-height: 20rem;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 10rem;
  @media screen and (min-width: 769px) {
    height: 12rem;
  }
`

const Number = styled.h5`
  opacity: 0.5;
  font-size: 6rem;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: ${DesignTokens.colors.white};
  @media screen and (min-width: 769px) {
    position: absolute;
    bottom: 1rem;
  }
`

const Subtitle = styled.p`
  opacity: 0;
  display: none;
  @media screen and (min-width: 769px) {
    display: block;
  }
`

const CustomLabel = styled(Label)`
  opacity: 0;
  display: none;
  @media screen and (min-width: 769px) {
    display: block;
  }
`

const StyledLink = styled(Link)`
  position: relative;
  align-items: flex-end;
  transition: all 0.2s ease-in;
  padding: 3rem ${DesignTokens.spacing[4]} 0;
  color: ${DesignTokens.colors.white};
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
  @media screen and (min-width: 768px) {
    padding: ${DesignTokens.spacing[7]};
  }
  @media screen and (max-width: 767px) {
    p {
      display: block;
      opacity: 1;
    }
    h5 {
      opacity: 0;
    }
    /* background: ${DesignTokens.colors.primary.alpha}; */
  }
  &:hover {
    p {
      display: block;
      opacity: 1;
    }
    h5 {
      opacity: 0;
    }
    background: ${DesignTokens.colors.primary.alpha};
  }
  height: 100%;
`

const StyledSolution = styled.li`
  margin: 0;
  padding: 1rem;
  background-size: cover;
  background-position: center right;
  @media screen and (min-width: 769px) {
    width: 25%;
    background-position: center;
  }
  h4 {
    margin-bottom: ${DesignTokens.spacing[4]};
    font-weight: 400;
    text-transform: uppercase;
    font-size: ${DesignTokens.fontSizes[5]};
  }
`

export default HeroSolutions
