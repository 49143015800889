import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroSolutions from "../components/heroSolutions"
import Footer from "../components/footer"

const SolutionsPage = ({ data }) => (
  <Layout>
    <SEO title={data.page.title} lang="it" />
    <HeroSolutions solutions={data.solutions.nodes} />
    <Footer />
  </Layout>
)

export default SolutionsPage

export const query = graphql`
  query SolutionsQuery {
    page: datoCmsSolutionsPage {
      title
      subtitle
      slug
    }
    solutions: allDatoCmsSolution(sort: {fields: position}) {
      nodes {
        id
        title
        subtitle
        slug
        heroImage {
          url
        }
        color {
          blue
          green
          alpha
          red
          hex
        }
      }
    }
  }
`
